import React from 'react';

import { Text } from '@marvelapp/ui';

import Page from '../components/Page';
import PageMeta from '../components/PageMeta';

export default function BlogIndex({ location }) {
  return (
    <Page location={location}>
      <PageMeta title="BDD docs" />

      <Text fontSize={[3, 4]} lineHeight={1.7}>
        {/* Add rules here */}
        some text here
      </Text>
    </Page>
  );
}
